<template>
  <div>
    <div class="flex flex-col gap-5 rounded-lg text-black dark:text-white">
      <div
        v-for="work in workList"
        :key="work.name"
        class="flex items-end justify-between space-x-2"
      >
        <ProjectListItem :work-data="work" />
      </div>
      <div class="flex justify-center">
        <UButton
          to="/Projects"
          label="All Projects"
          variant="ghost"
          icon="ph:arrow-right-light"
          trailing
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type workListInterface from '~/types/workListInterface'

const workList: workListInterface[] = [
  {
    name: 'Mori Music ',
    description: 'บอทดิสคอร์ดที่เขียนด้วย TypeScript และมีระบบเล่นเพลง และอื่นๆ',
    icon: 'ic:baseline-discord',
    link: 'https://discord.com/oauth2/authorize?client_id=999660896416714783&permissions=8&scope=bot%20applications.commands',
  },

  {
    name: 'Kuro Music',
    description: 'บอทดิสคอร์ดที่เขียนด้วย JavaScript และมีระบบเล่นเพลง และอื่นๆ',
    icon: 'ic:baseline-discord',
    link: 'https://discord.com/oauth2/authorize?client_id=1009472327009964053&permissions=8&scope=applications.commands%20bot',
  },
  {
    name: 'Documention for Mori Dev',
    description: 'เว็บไซต์ Documention ของบอท Mori และ Kuro',
    icon: 'ph:globe-simple-duotone',
    link: 'https://docs.jittiphat.site/',
  },
]
</script>
